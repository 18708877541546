// Creates button for roadmap view
gMap.addControl({
  position: 'right_center',
  content: 'Road Map',
  id: 'cd-roadMap',
  classes: 'custom-btn active',
  events: {
    click: function () {
      $('#map_canvas .active').removeClass('active')

      $(this).addClass('active')
      gMap.map.setMapTypeId(google.maps.MapTypeId.ROADMAP)
    }
  }
})

// Creates button for Hybrid view
gMap.addControl({
  position: 'right_center',
  content: 'Hybrid',
  id: 'cd-hybrid',
  classes: 'custom-btn',
  events: {
    click: function () {
      $('#map_canvas .active').removeClass('active')

      $(this).addClass('active')
      gMap.map.setMapTypeId(google.maps.MapTypeId.HYBRID)
    }
  }
})

// Creates button for satellite view
gMap.addControl({
  position: 'right_center',
  content: 'Satellite',
  id: 'cd-satellite',
  classes: 'custom-btn',
  events: {
    click: function () {
      $('#map_canvas .active').removeClass('active')

      $(this).addClass('active')
      gMap.map.setMapTypeId(google.maps.MapTypeId.SATELLITE)
    }
  }
})

// Add Zoom Controls
gMap.addControl({
  position: 'right_center',
  content: 'Zoom In',
  id: 'cd-zoom-in',
  events: {
    click: function () {
      gMap.zoomIn()
    }
  }
})

gMap.addControl({
  position: 'right_center',
  content: 'Zoom Out',
  id: 'cd-zoom-out',
  events: {
    click: function () {
      gMap.zoomOut()
    }
  }
})

gMap.addControl({
  position: 'right_center',
  content: 'Center',
  id: 'cd-center',
  events: {
    click: function () {
      gMap.setCenter(centerLat, centerLng)
    }
  }
})

// transit Button
$('#transit-btn').click(function (event) {
  event.preventDefault()
  trafficLayer.setMap(null)
  bikeLayer.setMap(null)
  $(this).toggleClass('active-overlay')
  if ($(this).hasClass('active-overlay')) {
    $('.active-overlay').not(this).removeClass('active-overlay')
    transitLayer.setMap(gMap.map)
  } else {
    transitLayer.setMap(null)
  }
})

// traffic Button
$('#traffic-btn').click(function (event) {
  event.preventDefault()
  bikeLayer.setMap(null)
  transitLayer.setMap(null)
  $(this).toggleClass('active-overlay')
  if ($(this).hasClass('active-overlay')) {
    $('.active-overlay').not(this).removeClass('active-overlay')
    trafficLayer.setMap(gMap.map)
  } else {
    trafficLayer.setMap(null)
  }
})

// Bike Button
$('#bike-btn').click(function (event) {
  event.preventDefault()
  transitLayer.setMap(null)
  trafficLayer.setMap(null)
  $(this).toggleClass('active-overlay')
  if ($(this).hasClass('active-overlay')) {
    $('.active-overlay').not(this).removeClass('active-overlay')
    bikeLayer.setMap(gMap.map)
  } else {
    bikeLayer.setMap(null)
  }
})

// Walking Distance Overlay
var circlesArray = []

var walkFive = new google.maps.Circle({
  map: gMap.map,
  radius: 402.33600,    // 5 miles in metres
  fillColor: '#e1da1c',
  strokeColor: 'transparent',
  fillOpacity: '0.30'
})

var walkTen = new google.maps.Circle({
  map: gMap.map,
  radius: 804.67200,    // 10 miles in metres
  fillColor: '#e1da1c',
  strokeColor: 'transparent',
  fillOpacity: '0.30'
})

var walkFifteen = new google.maps.Circle({
  map: gMap.map,
  radius: 1207.008,    // 15 miles in metres
  fillColor: '#e1da1c',
  strokeColor: 'transparent',
  fillOpacity: '0.30'
})

walkFive.bindTo('center', gMap.markers[1], 'position')
walkTen.bindTo('center', gMap.markers[1], 'position')
walkFifteen.bindTo('center', gMap.markers[1], 'position')

circlesArray.push(walkFive, walkTen, walkFifteen)
toggleCircles()

// Toggles circles
function toggleCircles () {
  if (circlesArray) {
    for (i in circlesArray) {
      circlesArray[i].setVisible(false)
    }
  }
}

$('#walking-distance-btn').click(function (event) {
  event.preventDefault()
  $(this).toggleClass('active-custom-overlay')
  if ($(this).hasClass('active-custom-overlay')) {
    for (i in circlesArray) {
      circlesArray[i].setVisible(true)
    }

    gMap.drawOverlay({
      lat: 40.752944,
      lng: -73.971250,
      content: '<div class="overlay">5 minute walk</div>'
    })

    gMap.drawOverlay({
      lat: 40.749131,
      lng: -73.971250,
      content: '<div class="overlay">10 minute walk</div>'
    })

    gMap.drawOverlay({
      lat: 40.745432,
      lng: -73.971250,
      content: '<div class="overlay">15 minute walk</div>'
    })
  } else {
    for (i in circlesArray) {
      circlesArray[i].setVisible(false)
    }
    gMap.removeOverlays()
  }
})
